import axios from "axios";
import { API } from "../../constants/constants";
import { ADDRESS_SEARCH } from "../../graphql/graphql-bff";

export type MemberAddressResponseType = {
    data: {
        memberAddress: {
            searchAddressModel: {
                addressLine: string;
            }[];
        };
    };
};


export const searchAddressRequest = async (
    searchParam: string,
    { ssoid, tpid, sid, aid, rid }: { ssoid: string; tpid: string; sid: string; aid: string; rid: string }
): Promise<MemberAddressResponseType> => {
    try {
        const config = {
            headers: { "x-aid": aid, "x-rid": rid, ssoid, tpid, sid },
        };

        const response = await axios.post(API.BASEURL, {
            query: ADDRESS_SEARCH,
            variables: { searchParam },
        }, config);

        return response.data.data.memberAddress.searchAddressModel;

    } catch (error: any) {
        throw new Error(`Error during address search: ${error.message || error}`);
    }
};
